// Copyright (C) 2021 TANNER AG

import { useUserQuery } from "../graphql";

const useUser = () => {
    const [{ data }] = useUserQuery();
    const user = data?.user;

    return { ...user, isAdmin: user?.role === "Admin" };
};

export default useUser;
