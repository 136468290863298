// Copyright (C) 2021 TANNER AG

import React from "react";
import { useDropzone } from "react-dropzone";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import useTranslation from "../hooks/usetranslation";

type Props = {
    onDrop?(files: File[]): void;
};

const FileUpload: React.FC<Props> = ({ onDrop }) => {
    const { t } = useTranslation();
    const { getRootProps, getInputProps } = useDropzone({
        accept: [".pdf", ".schema"],
        multiple: false,
        onDrop: (acceptedFiles) => {
            onDrop?.(acceptedFiles);
        },
    });

    return (
        <UploadContainer {...getRootProps()}>
            <input {...getInputProps()} />
            <Typography variant="subtitle1" component="p">
                {t("upload_text")}
            </Typography>
        </UploadContainer>
    );
};

const UploadContainer = styled("div")(({ theme }) => ({
    position: "relative",
    width: "100%",
    minHeight: "75px",
    backgroundColor: "#ebf7f8",
    border: "dashed",
    borderColor: theme.palette.divider,
    boxSizing: "border-box",
    cursor: "pointer",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
}));

export default FileUpload;
