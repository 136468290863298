// Copyright (C) 2021 TANNER AG

import React, { FormEvent, useState } from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { CloseIcon, SearchIcon } from "../Icons";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/usetranslation";

const SearchInput: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [value, setValue] = useState("");
    const handleSearch = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        navigate({ search: `?query=${value}` });
    };

    const handleReset = () => {
        setValue("");
        navigate({ search: `?query=` });
    };

    return (
        <Paper
            component="form"
            onSubmit={handleSearch}
            sx={{
                width: 350,
                p: "0 4px",
                display: "flex",
                alignItems: "center",
                borderRadius: 25,
                backgroundColor: "#ececec",
            }}
            elevation={0}
            variant="outlined"
        >
            <IconButton sx={{ p: "10px" }} aria-label="menu" type="submit">
                <SearchIcon fontSize="small" />
            </IconButton>
            <SearchTextField
                placeholder={t("search_placeholder")}
                value={value}
                onChange={(event) => setValue(event.currentTarget.value)}
            />
            {value && (
                <IconButton sx={{ p: "10px" }} aria-label="menu" onClick={handleReset}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            )}
        </Paper>
    );
};

const SearchTextField = styled(InputBase)(() => ({
    ml: 1,
    flex: 1,
    ".MuiInputBase-input": {
        paddingTop: 6,
    },
}));

export default SearchInput;
