// Copyright (C) 2021 TANNER AG

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#00a4dd",
            contrastText: "#fff",
        },
        secondary: {
            main: "#3e3d40",
        },
    },
    typography: {
        fontFamily: ["Roboto", "sans-serif"].join(", "),
        fontWeightBold: 500,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                body {
                    background-color: #fff;
                }
            `,
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 2,
                },
                outlined: {
                    borderColor: "transparent",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: "none",
                    "&:hover": {
                        textDecoration: "underline",
                    },
                },
            },
        },
    },
});

export default theme;
