// Copyright (C) 2021 TANNER AG

import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { DeleteIcon, OpenIcon } from "../Icons";
import { DeviceFile } from "../../types";
import { Maybe } from "../../lib/utils";
import BasicDeviceDetailsFile from "./BasicDeviceDetailsFile";
import useTranslation from "../../hooks/usetranslation";
import useBaseFileDelete from "../../hooks/usebasefiledelete";

type Props = {
    deviceId?: string;
    files?: Maybe<DeviceFile[]>;
};

const BaseDeviceDetailsDownloads: React.FC<Props> = ({ deviceId, files }) => {
    const { t } = useTranslation();
    const { deleteFile } = useBaseFileDelete(deviceId || "");

    return (
        <Box>
            <Stack direction="row" sx={{ px: 2 }}>
                <Typography variant="subtitle1" sx={{ flexGrow: 1, fontWeight: 500 }}>
                    {t("documents")}
                </Typography>
            </Stack>
            <List dense sx={{ px: 0 }}>
                {files?.map(({ filename, locale, url }, index) => (
                    <ListItem
                        key={index}
                        divider
                        dense
                        secondaryAction={
                            <>
                                <IconButton onClick={() => deleteFile(filename)} title={t("delete")}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                    edge="end"
                                    component="a"
                                    target="_blank"
                                    href={url || ""}
                                    title={t("preview")}
                                >
                                    <OpenIcon fontSize="small" />
                                </IconButton>
                            </>
                        }
                    >
                        <ListItemText primary={`${filename} (${locale})`} />
                    </ListItem>
                ))}
            </List>
            {deviceId && <BasicDeviceDetailsFile deviceId={deviceId} filenames={files?.map((file) => file.filename)} />}
        </Box>
    );
};

export default BaseDeviceDetailsDownloads;
