// Copyright (C) 2021 TANNER AG

import React from "react";
import Chip from "@mui/material/Chip";
import useTranslation from "../../hooks/usetranslation";

type Props = {
    locale?: string;
    totalFiles?: number;
    name?: string;
    withBaseFiles?: boolean;
};

type DeviceStatusType = { color: "info" | "success"; label: string };

const DeviceStatus: React.FC<Props> = ({ locale, name, withBaseFiles, totalFiles }) => {
    const { t } = useTranslation();
    let status: DeviceStatusType = { color: "info", label: t("open") };
    if (locale && name && (withBaseFiles || totalFiles)) {
        status = { color: "success", label: t("complete") };
    }

    return <Chip label={status.label} color={status.color} size="small" />;
};

export default DeviceStatus;
