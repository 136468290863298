// Copyright (C) 2021 TANNER AG

import React from "react";
import { useBaseDevicesArchivedQuery } from "../../graphql";
import Typography from "@mui/material/Typography";
import useTranslation from "../../hooks/usetranslation";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { formatTimestamp } from "../../lib/utils";
import Paper from "@mui/material/Paper";

type Props = {
    productId: string;
};

const BaseDeviceArchive: React.FC<Props> = ({ productId }) => {
    const [{ data }] = useBaseDevicesArchivedQuery({ variables: { productId }, pause: !productId });
    const { t } = useTranslation();

    if (!productId || !data?.baseDevicesArchived?.length) {
        return null;
    }

    return (
        <Paper sx={{ mx: 2, py: 1, mb: 2 }} variant="outlined">
            <Typography variant="subtitle1" sx={{ flexGrow: 1, fontWeight: 500, px: 2 }} gutterBottom>
                {t("archive")}
            </Typography>
            <List dense>
                {data?.baseDevicesArchived?.map((device) => (
                    <ListItem key={device.id} divider>
                        <ListItemText primary={`${formatTimestamp(device?.createdAt || "")} ${device?.note || ""}`} />
                    </ListItem>
                ))}
            </List>
        </Paper>
    );
};

export default BaseDeviceArchive;
