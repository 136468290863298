// Copyright (C) 2021 TANNER AG

import React from "react";
import Container from "@mui/material/Container";
import DeviceList from "../components/device/DeviceList";
import DeviceListItem from "../components/device/DeviceListItem";
import { useNavigate, useLocation } from "react-router-dom";
import DeviceDetails from "../components/devicedetails/DeviceDetails";
import { useDevicesQuery } from "../graphql";
import Box from "@mui/material/Box";
import DeviceDetailsDrawer from "../components/devicedetails/DeviceDetailsDrawer";
import ListPagination from "../components/ListPagination";
import { PAGINATION_PAGE_SIZE } from "../types";
import Toolbar from "@mui/material/Toolbar";
import SearchInput from "../components/search/SearchInput";
import CreateDevice from "../components/createdevice/CreateDevice";

const CustomDevices: React.FC = () => {
    const { search, pathname } = useLocation();
    const params = new URLSearchParams(search);
    const deviceId = params.get("id");
    const query = params.get("query") || undefined;
    const page = parseInt(params.get("page") || "1", 10);

    const navigate = useNavigate();
    const [{ data }] = useDevicesQuery({
        variables: {
            pagination: { limit: PAGINATION_PAGE_SIZE, offset: PAGINATION_PAGE_SIZE * (page - 1) },
            filter: { query },
        },
    });

    const devices = data?.devices || [];
    const total = data?.devicesCount || 0;

    return (
        <Box sx={{ display: "flex" }}>
            <Container maxWidth={false} sx={{ py: 2 }}>
                <Toolbar disableGutters>
                    <CreateDevice />
                    <SearchInput />
                </Toolbar>
                <DeviceList>
                    {devices.map((device, index) => (
                        <DeviceListItem
                            key={index}
                            id={device.id}
                            eqNo={device.eqNo}
                            productName={device?.baseDevice?.name}
                            updatedAt={device?.updatedAt}
                            createdAt={device?.createdAt}
                            selected={device.id === deviceId}
                            locale={device?.locale}
                            totalFiles={device?.files?.length || 0}
                            withBaseFiles={device?.withBaseFiles}
                        />
                    ))}
                </DeviceList>
                <ListPagination total={total} page={page} />
            </Container>
            <DeviceDetailsDrawer open={Boolean(deviceId)}>
                <DeviceDetails id={deviceId} onClose={() => navigate(pathname, { replace: true })} />
            </DeviceDetailsDrawer>
        </Box>
    );
};

export default CustomDevices;
