// Copyright (C) 2021 TANNER AG

import { createContext, FC, useContext, useEffect, useState } from "react";
import Login from "../views/Login";
import { LoginInputs, LoginPayload } from "../types";
import { API_LOGIN_URL } from "../lib/env";
import { client } from "../lib/urql";
import { resetToken, setToken } from "../lib/auth";

type AuthContextProps = {
    loading: boolean;
    error: string;
    login(input: LoginInputs): void;
    logout(): void;
};

const AuthContext = createContext<AuthContextProps>({
    loading: false,
    error: "",
    login: () => null,
    logout: () => null,
});

export const AuthProvider: FC = ({ children }) => {
    const [loading] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [error, setError] = useState("");

    const checkAuth = async () => {
        const { error } = await client.query("{ health }").toPromise();
        setIsAuthorized(!error);
    };

    const login = async (input: LoginInputs) => {
        const formData = new FormData();
        Object.entries(input).forEach(([name, value]) => formData.set(name, value));

        const response = await fetch(API_LOGIN_URL, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            setError(await response.text());
            return;
        }

        const data: LoginPayload = await response.json();
        setToken(data.token);

        await checkAuth();
    };

    const logout = async () => {
        resetToken();
        await checkAuth();
        window.location.reload();
    };

    useEffect(() => {
        checkAuth();
    }, []);

    return (
        <AuthContext.Provider value={{ loading, login, logout, error }}>
            {isAuthorized ? children : <Login />}
        </AuthContext.Provider>
    );
};

const useAuth = () => useContext(AuthContext);

export default useAuth;
