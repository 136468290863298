// Copyright (C) 2021 TANNER AG

import React from "react";
import Stack from "@mui/material/Stack";
import { CloseIcon } from "../Icons";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { formatTimestamp, Maybe } from "../../lib/utils";
import Paper from "@mui/material/Paper";
import { useBaseDeviceQuery } from "../../graphql";
import BaseDeviceDetailsDownloads from "./BaseDeviceDetailsDownloads";
import useTranslation from "../../hooks/usetranslation";
import BaseDeviceArchive from "../basedevicearchive/BaseDeviceArchive";
import CreateBaseDeviceVersion from "../createbasedeviceversion/CreateBaseDeviceVersion";

type Props = {
    id?: Maybe<string>;
    onClose(): void;
};

const BaseDeviceDetails: React.FC<Props> = ({ id, onClose }) => {
    const { t } = useTranslation();
    const [{ data }] = useBaseDeviceQuery({ variables: { id: id || "" }, pause: !id });
    const baseDevice = data?.baseDevice;

    if (!id) {
        return null;
    }

    return (
        <>
            <Toolbar>
                <IconButton edge="start" sx={{ mr: 1 }} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6">{baseDevice?.product?.name}</Typography>
                <Stack direction="row" sx={{ marginLeft: "auto" }}>
                    <Stack sx={{ marginLeft: "auto" }} spacing={1} direction="row" alignItems="center">
                        <CreateBaseDeviceVersion product={baseDevice?.product} />
                    </Stack>
                </Stack>
            </Toolbar>
            <Paper sx={{ mx: 2, px: 2, py: 1, mb: 2 }} variant="outlined">
                <Typography variant="subtitle1" sx={{ flexGrow: 1, fontWeight: 500 }} gutterBottom>
                    {t("device_information")}
                </Typography>
                <Stack sx={{ mb: 1 }}>
                    <Typography variant="body2">
                        {t("created_at")}: {formatTimestamp(baseDevice?.createdAt || "")}
                    </Typography>
                    <Typography variant="body2">
                        {t("updated_at")}: {formatTimestamp(baseDevice?.updatedAt || "")}
                    </Typography>
                    <Typography variant="body2">
                        {t("model")}: {baseDevice?.product?.name}
                    </Typography>
                    <Typography variant="body2">
                        {t("note")}: {baseDevice?.note}
                    </Typography>
                </Stack>
            </Paper>
            <Paper sx={{ mx: 2, py: 1, mb: 2 }} variant="outlined">
                <BaseDeviceDetailsDownloads deviceId={id} files={baseDevice?.files} />
            </Paper>
            {baseDevice?.product?.id && <BaseDeviceArchive productId={baseDevice?.product?.id} />}
        </>
    );
};

export default BaseDeviceDetails;
