// Copyright (C) 2021 TANNER AG

import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import useTranslation from "../../hooks/usetranslation";

type Props = {
    children?: React.FC<{ onClose(): void }>;
};

const DeviceDetailsFileDialog: React.FC<Props> = ({ children }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Stack direction="row" justifyContent="flex-end" sx={{ px: 2 }}>
            <Button variant="outlined" size="small" onClick={() => setOpen(true)}>
                {t("add")}
            </Button>
            <Dialog open={open} onClose={handleClose} fullWidth>
                {children?.({ onClose: handleClose })}
            </Dialog>
        </Stack>
    );
};

export default DeviceDetailsFileDialog;
