// Copyright (C) 2021 TANNER AG

import { API_QRCODE_URL } from "../lib/env";

const useQRCode = (deviceUrl?: string) => {
    const generateUrl = (width: number = 150, margin: number = 0) => {
        const url = new URL(API_QRCODE_URL);
        url.searchParams.set("data", deviceUrl || "");
        url.searchParams.set("width", width.toString());
        url.searchParams.set("margin", margin.toString());

        return url.href;
    };

    return { generateUrl, deviceUrl };
};

export default useQRCode;
