// Copyright (C) 2021 TANNER AG

import React, { SyntheticEvent } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useBaseDevicesActiveQuery } from "../graphql";
import { ProductOption } from "../types";
import useTranslation from "../hooks/usetranslation";

type Props = {
    onChange?(value: ProductOption | null): void;
    baseDevice?: ProductOption | null;
};

const BaseDeviceSelect: React.FC<Props> = ({ onChange, baseDevice }) => {
    const { t } = useTranslation();
    const [{ data }] = useBaseDevicesActiveQuery();
    const options: ProductOption[] =
        data?.baseDevicesActive?.map((baseDevice) => ({
            id: baseDevice.id,
            name: baseDevice?.product?.name || "",
        })) || [];

    const handleSelect = (event: SyntheticEvent, value: ProductOption | null) => {
        onChange?.(value);
    };

    return (
        <Autocomplete
            value={baseDevice}
            autoHighlight
            options={options}
            getOptionLabel={(option) => option.name}
            onChange={handleSelect}
            renderInput={(params) => <TextField {...params} margin="normal" label={t("model")} />}
        />
    );
};

export default BaseDeviceSelect;
