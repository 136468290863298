// Copyright (C) 2021 TANNER AG

import React from "react";
import Container from "@mui/material/Container";
import { useNavigate, useLocation } from "react-router-dom";
import { useBaseDevicesQuery } from "../graphql";
import Box from "@mui/material/Box";
import DeviceDetailsDrawer from "../components/devicedetails/DeviceDetailsDrawer";
import CreateBaseDevice from "../components/createbasedevice/CreateBaseDevice";
import BaseDeviceDetails from "../components/basedevicedetails/BaseDeviceDetails";
import BaseDeviceList from "../components/basedevicelist/BaseDeviceList";
import BaseDeviceListItem from "../components/basedevicelist/BaseDeviceListItem";
import Toolbar from "@mui/material/Toolbar";

const CustomDevices: React.FC = () => {
    const { search, pathname } = useLocation();
    const params = new URLSearchParams(search);
    const deviceId = params.get("id");

    const navigate = useNavigate();
    const [{ data }] = useBaseDevicesQuery({ variables: { pagination: { limit: 1000 } } });

    const baseDevices = data?.baseDevices || [];

    return (
        <Box sx={{ display: "flex" }}>
            <Container maxWidth={false} sx={{ py: 2 }}>
                <Toolbar disableGutters>
                    <CreateBaseDevice />
                </Toolbar>
                <BaseDeviceList>
                    {baseDevices.map((device, index) => (
                        <BaseDeviceListItem
                            key={index}
                            id={device.id}
                            productName={device?.product?.name}
                            updatedAt={device?.updatedAt}
                            createdAt={device?.createdAt}
                            selected={device.id === deviceId}
                            note={device?.note}
                        />
                    ))}
                </BaseDeviceList>
            </Container>
            <DeviceDetailsDrawer open={Boolean(deviceId)}>
                <BaseDeviceDetails id={deviceId} onClose={() => navigate(pathname, { replace: true })} />
            </DeviceDetailsDrawer>
        </Box>
    );
};

export default CustomDevices;
