// Copyright (C) 2021 TANNER AG

import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { ProductOption } from "../../types";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useCreateDeviceMutation } from "../../graphql";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/usetranslation";
import BaseDeviceSelect from "../BaseDeviceSelect";
import { routes } from "../../lib/utils";

type Props = {
    onClose(): void;
};

const CreateDeviceForm: React.FC<Props> = ({ onClose }) => {
    const { t } = useTranslation();
    const [eqNo, setEqNo] = useState("");
    const [baseDevice, setBaseDevice] = useState<ProductOption | null>(null);
    const [{ fetching }, createDevice] = useCreateDeviceMutation();
    const navigate = useNavigate();

    const handleCreate = async () => {
        if (!eqNo) {
            return;
        }

        const { data } = await createDevice({
            data: { eqNo, ...(baseDevice && { baseDevice: { id: baseDevice.id, name: baseDevice.name } }) },
        });

        navigate(`${routes.qrCodes}?id=${data?.createDevice?.id}`);

        onClose();
    };

    return (
        <>
            <DialogTitle>{t("qr_code_new")}</DialogTitle>
            <DialogContent>
                <TextField
                    label={t("eq_no")}
                    fullWidth
                    margin="normal"
                    autoFocus
                    value={eqNo}
                    onChange={(event) => setEqNo(event.currentTarget.value as string)}
                />
                <BaseDeviceSelect onChange={setBaseDevice} baseDevice={baseDevice} />
                {fetching && <Typography>Loading ...</Typography>}
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 2 }}>
                <Button variant="outlined" onClick={onClose}>
                    {t("cancel")}
                </Button>
                <Button variant="contained" onClick={handleCreate} disabled={!eqNo || !baseDevice}>
                    {t("create")}
                </Button>
            </DialogActions>
        </>
    );
};

export default CreateDeviceForm;
