// Copyright (C) 2021

import React, { useState } from "react";
import MenuBarDrawer from "./MenuBarDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import logo from "../../assets/logo_sm.png";
import Stack from "@mui/material/Stack";
import backgroundImg from "../../assets/background.jpg";
import { Diagram2Icon, DoubleArrowIcon, PeopleFillIcon, QRCodeIcon } from "../Icons";
import User from "../User";
import useUser from "../../hooks/useuser";
import useTranslation from "../../hooks/usetranslation";
import { routes } from "../../lib/utils";
import MenuBarItem from "./MenuBarItem";

const MenuBar: React.FC = () => {
    const [open, setOpen] = useState(false);
    const { isAdmin } = useUser();
    const { t } = useTranslation();

    return (
        <MenuBarDrawer open={open}>
            <Box sx={{ display: "flex", alignItems: "center", px: 2, pt: 2, pb: 2, zIndex: 4 }}>
                <Box component="img" src={logo} alt="FrigorTec Logo" sx={{ width: 42, borderRadius: 2 }} />
                <Stack sx={{ ml: 2 }}>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            lineHeight: 1,
                            fontWeight: 500,
                            fontSize: "1.3rem",
                            letterSpacing: 2,
                        }}
                    >
                        Digital Portal
                    </Typography>
                    <Typography variant="caption">FrigorTec</Typography>
                </Stack>
            </Box>
            <MenuList>
                <MenuBarItem title={t("qr_codes")} to={routes.qrCodes} icon={<QRCodeIcon />} />
                <MenuBarItem title={t("device_management")} to={routes.devices} icon={<Diagram2Icon />} />
                {isAdmin && <MenuBarItem title={t("user_management")} to={routes.users} icon={<PeopleFillIcon />} />}
            </MenuList>
            <MenuList sx={{ marginTop: "auto" }} disablePadding>
                <User />
                <ListItem button onClick={() => setOpen(!open)}>
                    <ListItemIcon>
                        <CloseIcon className={clsx({ open })} />
                    </ListItemIcon>
                </ListItem>
            </MenuList>
            <Background style={{ backgroundImage: `url(${backgroundImg})` }} />
        </MenuBarDrawer>
    );
};

const MenuList = styled(List)(({ theme }) => ({
    zIndex: 4,
    "& .MuiListItem-root": {
        paddingLeft: theme.spacing(3),
        "&.active": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.primary.main,

            "& .MuiListItemIcon-root": {
                color: theme.palette.primary.main,
            },
        },
    },
    "& .MuiListItemIcon-root": {
        minWidth: 50,
        color: theme.palette.common.white,
    },
}));

const CloseIcon = styled(DoubleArrowIcon)(({ theme }) => ({
    zIndex: 4,
    color: theme.palette.common.white,
    "&.open": {
        transform: "rotate(180deg)",
    },
}));

const Background = styled("div")(() => ({
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "block",
    position: "absolute",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    zIndex: 1,
}));

export default MenuBar;
