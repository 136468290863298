// Copyright (C) 2021

import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import clsx from "clsx";

type Props = {
    title: string;
    to: string;
    icon: React.ReactNode;
};

const MenuBarItem: React.FC<Props> = ({ title, to, icon }) => {
    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end: true });

    return (
        <ListItem button title={title} component={Link} to={to} className={clsx({ active: Boolean(match) })}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={title} />
        </ListItem>
    );
};

export default MenuBarItem;
