// Copyright (C) 2021 TANNER AG

import React, { useState } from "react";
import { ProductOption } from "../../types";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useBaseDeviceExistsQuery, useCreateBaseDeviceMutation } from "../../graphql";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/usetranslation";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";

type Props = {
    onClose(): void;
    product?: ProductOption;
};

const CreateBaseDeviceVersionForm: React.FC<Props> = ({ onClose, product }) => {
    const { t } = useTranslation();
    const [note, setNote] = useState("");
    const [{ fetching }, createDevice] = useCreateBaseDeviceMutation();
    const [{ data }] = useBaseDeviceExistsQuery({
        variables: { productId: product?.id || "" },
        pause: !product?.id,
        requestPolicy: "network-only",
    });
    const navigate = useNavigate();

    const handleCreate = async () => {
        if (!product) {
            return;
        }

        const { data } = await createDevice({
            data: { note, ...(product && { product: { id: product.id, name: product.name } }) },
        });

        navigate(`/standard?id=${data?.createBaseDevice?.id}`);

        onClose();
    };

    return (
        <>
            <DialogTitle>
                {t("generation_new")} für {product?.name}
            </DialogTitle>
            <DialogContent>
                <TextField
                    label={t("note")}
                    fullWidth
                    margin="normal"
                    value={note}
                    onChange={(event) => setNote(event.currentTarget.value as string)}
                />
                {fetching && <Typography>Loading ...</Typography>}
                {data?.baseDeviceExists && <Alert severity="info">{t("generation_new_info")}</Alert>}
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 2 }}>
                <Button variant="outlined" onClick={onClose}>
                    {t("cancel")}
                </Button>
                <Button variant="contained" onClick={handleCreate}>
                    {t("create")}
                </Button>
            </DialogActions>
        </>
    );
};

export default CreateBaseDeviceVersionForm;
