// Copyright (C) 2021

import React from "react";
import MuiDrawer from "@mui/material/Drawer";
import { styled, Theme, CSSObject } from "@mui/material/styles";

type Props = {
    open?: boolean;
};

const drawerWidth = 280;

const MenuBarDrawer: React.FC<Props> = ({ open, children }) => {
    return (
        <Drawer variant="permanent" open={open}>
            {children}
        </Drawer>
    );
};

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    "& .MuiPaper-root": {
        color: theme.palette.common.white,
        "&::after": {
            top: 0,
            width: "100%",
            height: "100%",
            content: "''",
            zIndex: 2,
            position: "absolute",
            backgroundColor: theme.palette.primary.main,
            opacity: 0.8,
        },
    },
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

export default MenuBarDrawer;
