// Copyright (C) 2021 TANNER AG

import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { DeleteIcon, OpenIcon } from "../Icons";
import { styled } from "@mui/material/styles";
import { DeviceFile } from "../../types";
import { Maybe } from "../../lib/utils";
import DeviceDetailsFile from "./DeviceDetailsFile";
import useFileDelete from "../../hooks/usefiledelete";
import useTranslation from "../../hooks/usetranslation";
import { Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

type Props = {
    deviceId?: string;
    files?: Maybe<DeviceFile[]>;
    baseFiles?: Maybe<DeviceFile[]>;
    withBaseFiles: boolean;
    updateWithBaseDownloads(checked: boolean): void;
};

const DeviceDetailsDownloads: React.FC<Props> = ({
    deviceId,
    files,
    baseFiles,
    withBaseFiles,
    updateWithBaseDownloads,
}) => {
    const { t } = useTranslation();
    const { deleteFile } = useFileDelete(deviceId || "");

    return (
        <Box>
            <Stack sx={{ position: "relative", px: 2, alignItems: "center" }} direction="row">
                <Typography variant="subtitle1" sx={{ flexGrow: 1, fontWeight: 500 }}>
                    {t("documents")}
                </Typography>
                <FormControlLabel
                    sx={{ fontSize: "0.875rem" }}
                    control={
                        <Checkbox
                            size="small"
                            sx={{ p: 1 }}
                            checked={withBaseFiles}
                            onChange={(_, checked) => updateWithBaseDownloads(checked)}
                        />
                    }
                    label={<Typography variant="caption">{t("default_documents")}</Typography>}
                    labelPlacement="start"
                    disableTypography
                />
            </Stack>
            <List dense sx={{ px: 0 }}>
                {withBaseFiles &&
                    baseFiles?.map(({ filename, url }, index) => (
                        <DownloadListItem
                            key={index}
                            divider
                            disabled
                            secondaryAction={
                                <IconButton
                                    edge="end"
                                    component="a"
                                    target="_blank"
                                    href={url || ""}
                                    title={t("preview")}
                                >
                                    <OpenIcon fontSize="small" />
                                </IconButton>
                            }
                        >
                            <ListItemText primary={`${filename}`} />
                        </DownloadListItem>
                    ))}
                {files?.map(({ filename, url }, index) => (
                    <DownloadListItem
                        key={index}
                        divider
                        secondaryAction={
                            <>
                                <IconButton onClick={() => deleteFile(filename)} title={t("delete")}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                    edge="end"
                                    component="a"
                                    target="_blank"
                                    href={url || ""}
                                    title={t("preview")}
                                >
                                    <OpenIcon fontSize="small" />
                                </IconButton>
                            </>
                        }
                    >
                        <ListItemText primary={`${filename}`} />
                    </DownloadListItem>
                ))}
            </List>
            {deviceId && <DeviceDetailsFile deviceId={deviceId} filenames={files?.map((file) => file.filename)} />}
        </Box>
    );
};

const DownloadListItem = styled(ListItem)(({ theme }) => ({
    "& .MuiListItemButton-root": {},
}));

export default DeviceDetailsDownloads;
