// Copyright (C) 2021 TANNER AG

import React, { SyntheticEvent } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useProductsQuery } from "../graphql";
import { ProductOption } from "../types";
import useTranslation from "../hooks/usetranslation";
import { sortOption } from "../lib/utils";

type Props = {
    onChange?(value: ProductOption | null): void;
    product?: ProductOption | null;
    autoFocus?: boolean;
};

const ProductSelect: React.FC<Props> = ({ onChange, product, autoFocus }) => {
    const { t } = useTranslation();
    const [{ data }] = useProductsQuery();
    const options: ProductOption[] =
        data?.products
            ?.map((product) => ({
                id: product.id,
                name: `${product?.productGroup?.name || ""} ${product.name}`.trim(),
            }))
            ?.sort(sortOption) || [];

    const handleSelect = (event: SyntheticEvent, value: ProductOption | null) => {
        onChange?.(value);
    };

    return (
        <Autocomplete
            value={product}
            autoHighlight
            options={options}
            getOptionLabel={(option) => option.name}
            onChange={handleSelect}
            renderInput={(params) => <TextField {...params} autoFocus={autoFocus} margin="normal" label={t("model")} />}
        />
    );
};

export default ProductSelect;
