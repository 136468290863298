// Copyright (C) 2021 TANNER AG

import React, { useState } from "react";
import useUser from "../../hooks/useuser";
import useTranslation from "../../hooks/usetranslation";
import { useDeleteDeviceMutation } from "../../graphql";
import IconButton from "@mui/material/IconButton";
import { MoreIcon } from "../Icons";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

type Props = {
    deviceId: string;
};

const DeviceDetailsDelete: React.FC<Props> = ({ deviceId }) => {
    const { t } = useTranslation();
    const { isAdmin } = useUser();
    const [, deleteDevice] = useDeleteDeviceMutation();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    if (!isAdmin) {
        return null;
    }

    return (
        <>
            <IconButton onClick={handleClick} title={t("options")}>
                <MoreIcon fontSize="small" />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <List disablePadding>
                    <ListItem button>
                        <ListItemText primary={t("delete")} onClick={() => deleteDevice({ id: deviceId })} />
                    </ListItem>
                </List>
            </Popover>
        </>
    );
};

export default DeviceDetailsDelete;
