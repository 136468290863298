// Copyright (C) 2021 TANNER AG

import format from "date-fns/format";
import intervalToDuration from "date-fns/intervalToDuration";

export const routes = {
    root: "/",
    qrCodes: "/qrcodes",
    devices: "/devices",
    users: "/users",
};

export type Maybe<T> = T | null;

export const formatDate = (date: Date) => format(date, "dd.MM.yyyy HH:mm");

export const formatTimestamp = (timestamp: string) => {
    const ts = parseInt(timestamp);
    if (isNaN(ts)) {
        return "";
    }

    return formatDate(new Date(ts));
};

export const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
};

export const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const dateDuration = (date: Date) => {
    const duration = intervalToDuration({ start: new Date(), end: date });
    const entry = Object.entries(duration).find(([, value]) => value > 0);
    if (!entry) {
        return "";
    }

    const [unit, value] = entry;
    return `${value} ${unit}`;
};

export const sortOption = (a: { name: string }, b: { name: string }) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    return 0;
};
