// Copyright (C) 2021 TANNER AG

import { createClient, cacheExchange } from "urql";
import { multipartFetchExchange } from "@urql/exchange-multipart-fetch";
import { API_GRAPHQL_URL } from "./env";
import { getToken } from "./auth";

export const client = createClient({
    url: API_GRAPHQL_URL,
    fetchOptions: () => {
        const token = getToken();
        return {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
    },
    exchanges: [cacheExchange, multipartFetchExchange],
});
