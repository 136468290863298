// Copyright (C) 2021 TANNER AG

import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import { formatTimestamp, Maybe } from "../../lib/utils";
import { ChevronRightIcon } from "../Icons";

type Props = {
    id: string;
    productName?: string;
    note?: string;
    updatedAt?: Maybe<string>;
    createdAt?: Maybe<string>;
    selected?: boolean;
};

const BaseDeviceListItem: React.FC<Props> = ({ id, createdAt, productName, note, updatedAt, selected }) => {
    return (
        <TableRow hover data-id={id} selected={selected}>
            <TableCell>{productName || "-"}</TableCell>
            <TableCell>{note || "-"}</TableCell>
            <TableCell>{formatTimestamp(updatedAt || "")}</TableCell>
            <TableCell>{formatTimestamp(createdAt || "")}</TableCell>
            <TableCell padding="none" align="right">
                <IconButton color="primary" component={Link} to={`?id=${id}`}>
                    <ChevronRightIcon fontSize="small" />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default BaseDeviceListItem;
