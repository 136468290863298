// Copyright (C) 2021 TANNER AG

import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

const Footer: React.FC = () => {
    return (
        <AppBar
            position="static"
            elevation={0}
            sx={{ top: "auto", bottom: 0, bgcolor: "transparent", color: "text.primary" }}
        >
            <Toolbar variant="dense">
                <Typography variant="caption">© {new Date().getFullYear()} FrigorTec GmbH</Typography>
            </Toolbar>
        </AppBar>
    );
};

export default Footer;
