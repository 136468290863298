// Copyright (C) 2021 TANNER AG

import React, { useState } from "react";
import { formatBytes, formatTimestamp } from "../../lib/utils";
import FileUpload from "../FileUpload";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import LocaleSelect from "../LocaleSelect";
import { Locale } from "../../types";
import useTranslation from "../../hooks/usetranslation";
import useBaseFileUpload from "../../hooks/usebasefileupload";
import Alert from "@mui/material/Alert";

type Props = {
    deviceId: string;
    filenames?: string[];
    onClose(): void;
};

const BaseDeviceDetailsFileAdd: React.FC<Props> = ({ deviceId, onClose, filenames }) => {
    const { t } = useTranslation();
    const [file, setFile] = useState<File>();
    const [locale, setLocale] = useState<Locale | null>(null);
    const { loading, upload } = useBaseFileUpload(deviceId);

    const fileNameExists = Boolean(file && filenames?.includes(file?.name));

    const handleDrop = (file: File[]) => {
        if (!file.length) {
            return;
        }

        setFile(file[0]);
    };

    const handleUpload = async () => {
        if (!locale) {
            return;
        }

        await upload(file, locale.id);
        await onClose();
    };

    return (
        <>
            <DialogTitle>{t("add_document")}</DialogTitle>
            <DialogContent>
                {Boolean(file) ? (
                    <Stack>
                        <Typography variant="subtitle2">{file?.name}</Typography>
                        <Typography variant="caption">Size: {formatBytes(file?.size || 0)}</Typography>
                        <Typography variant="caption">Type: {file?.type}</Typography>
                        <Typography variant="caption">
                            {t("updated_at")}: {formatTimestamp(file?.lastModified?.toString() || "")}
                        </Typography>
                    </Stack>
                ) : (
                    <FileUpload onDrop={handleDrop} />
                )}
                <LocaleSelect locale={locale} onChange={setLocale} />
                {fileNameExists && <Alert severity="error">{t("document_exists")}</Alert>}
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 2 }}>
                <Button variant="outlined" onClick={onClose}>
                    {t("cancel")}
                </Button>
                <Button
                    variant="contained"
                    onClick={handleUpload}
                    disabled={loading || !locale || !file || fileNameExists}
                >
                    {t("add")}
                </Button>
            </DialogActions>
        </>
    );
};

export default BaseDeviceDetailsFileAdd;
