// Copyright (C) 2021 TANNER AG

import { useUploadDeviceFileMutation } from "../graphql";

const useFileUpload = (deviceId: string) => {
    const [{ error, fetching }, executeUpload] = useUploadDeviceFileMutation();

    const upload = async (file?: File, locale?: string) => {
        if (!file) {
            return;
        }

        await executeUpload({ id: deviceId, file, locale });
    };

    return { upload, loading: fetching, error };
};

export default useFileUpload;
