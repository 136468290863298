// Copyright (C) TANNER AG 2021

import React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import LoginForm from "../components/login/LoginForm";
import { ReactComponent as Logo } from "../assets/logo.svg";
import backgroundImg from "../assets/background.jpg";

const Login: React.FC = () => {
    return (
        <Root>
            <Banner>
                <BannerBackground style={{ backgroundImage: `url(${backgroundImg})` }} />
            </Banner>
            <Wrapper>
                <Card>
                    <LoginForm />
                </Card>
                <Copyright variant="caption">© {new Date().getFullYear()} FrigorTec GmbH</Copyright>
                <Logo className="logo" />
            </Wrapper>
        </Root>
    );
};

const Root = styled("div")(({ theme }) => ({
    display: "flex",
    height: "100vh",

    "& .logo": {
        position: "absolute",
        top: 0,
        left: 0,
        width: 160,
        margin: theme.spacing(4, 4),
    },
}));

const Banner = styled("div")(({ theme }) => ({
    flexGrow: 1,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
        display: "none",
    },
    "&::after": {
        top: 0,
        width: "100%",
        height: "100%",
        content: "''",
        zIndex: 2,
        position: "absolute",
        backgroundColor: theme.palette.primary.main,
        opacity: 0.8,
    },
}));

const BannerBackground = styled("div")(({ theme }) => ({
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "absolute",
    zIndex: 1,
}));

const Wrapper = styled("div")(({ theme }) => ({
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    padding: theme.spacing(0, 2),
    width: "100%",
    maxWidth: 600,
}));

const Card = styled("div")(() => ({
    width: "100%",
    maxWidth: 450,
}));

const Copyright = styled(Typography)(({ theme }) => ({
    position: "absolute",
    bottom: 0,
    left: 0,
    margin: theme.spacing(1, 2),
}));

export default Login;
