// Copyright (C) 2021 TANNER AG

import React, { SyntheticEvent } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Locale } from "../types";
import useLocales from "../hooks/uselocales";
import useTranslation from "../hooks/usetranslation";

type Props = {
    onChange?(value: Locale | null): void;
    locale?: Locale | null;
};

const LocaleSelect: React.FC<Props> = ({ onChange, locale }) => {
    const { t } = useTranslation();
    const { locales: options } = useLocales();

    const handleSelect = (event: SyntheticEvent, value: Locale | null) => {
        onChange?.(value);
    };

    return (
        <Autocomplete
            value={locale}
            autoHighlight
            options={options}
            getOptionLabel={(option) => option.displayName}
            onChange={handleSelect}
            renderInput={(params) => <TextField {...params} margin="normal" label={t("language")} />}
        />
    );
};

export default LocaleSelect;
