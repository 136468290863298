// Copyright (C) 2021 TANNER AG

import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import CreateBaseDeviceVersionForm from "./CreateBaseDeviceVersionForm";
import useTranslation from "../../hooks/usetranslation";
import { Product } from "../../graphql";

type Props = {
    product?: Product;
};

const CreateBaseDeviceVersion: React.FC<Props> = ({ product }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    return (
        <>
            <Button onClick={handleOpen} variant="contained" size="small" disabled={!product}>
                {t("generation_new")}
            </Button>
            <Dialog onClose={handleClose} open={open} fullWidth>
                <CreateBaseDeviceVersionForm onClose={handleClose} product={product} />
            </Dialog>
        </>
    );
};

export default CreateBaseDeviceVersion;
